import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import plausible from "utils/plausible";

/**
 * Tracks a page view with MixPanel.
 *
 * Only use this for routes that have parameters such as `/edit/123`, view newsletter etc.
 * Pass an empty object if there are no properties. A falsy value for properties will not track the event.
 */
export default function useTrackPageView(pageName, properties) {
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    // don't track twice
    if (tracked) {
      return;
    }

    // don't track if dependencies are not ready
    if (!pageName || !properties) {
      return;
    }

    // track
    mixpanel.track(`Page View`, { pathname: window.location.pathname, ...properties });
    plausible.track(`Page View`, {
      pathname: window.location.pathname,
    });
    setTracked(true);
  }, [tracked, pageName, properties]);
}

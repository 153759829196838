import { Icon, P2, Stack, useConfig } from "@mailbrew/uikit";
import kFormatter from "utils/kFormatter";

export default function BrewSubscribersBadge({ newsletter, onClick }) {
  const config = useConfig();
  if (newsletter.subscribers_count < 1) return null;
  return (
    <Stack
      gap="3px"
      style={{
        background: config.colors.bg5,
        borderRadius: config.appearance.smallRadius,
        padding: "6px 9px",
        cursor: "pointer",
      }}
      onClick={onClick}
      noWrap
    >
      <P2 noWrap weight="600" color={config.colors.c2} centerAt={860} style={{ cursor: "pointer" }}>
        {kFormatter(newsletter.subscribers_count)}
      </P2>
      <Icon color={config.colors.c2} name="people" size="14px" offset="-1px" />
    </Stack>
  );
}

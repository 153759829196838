import { formatDailySchedule, formatWeeklySchedule, formatOrdinal, formatTimes } from "./formatSchedules";
import { migrateSchedule } from "./schedules";

export default function humanFriendlySchedule(schedule) {
  const migrated = migrateSchedule(schedule);

  switch (schedule.type) {
    case "daily": {
      if (migrated.exclude_weekends) {
        return `Mon - Fri at ${formatDailySchedule(migrated)}`;
      } else {
        return `Daily at ${formatDailySchedule(migrated)}`;
      }
    }
    case "weekly": {
      return formatWeeklySchedule(migrated);
    }
    case "monthly": {
      return `${formatOrdinal(migrated.day_of_month)} of each month at ${formatTimes(migrated.times)}`;
    }

    default:
      return "unknown schedule";
  }
}

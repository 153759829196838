import moment from "moment";
import prefers12HoursFormat from "./detectTimeFormatPreference";
import { allWeekdaysDoubleLetter, allWeekdaysLong, migrateSchedule } from "./schedules";
import { capitalize } from "./string";

function formatDailySchedule(schedule) {
  const migrated = migrateSchedule(schedule);
  return formatTimes(migrated.times);
}

function formatWeeklySchedule(schedule) {
  const migrated = migrateSchedule(schedule);
  const weekdays = migrated.weekdays;

  if (weekdays.length === 1) {
    const weekdayLong = allWeekdaysLong[weekdays[0]];
    return `${capitalize(weekdayLong)} • ${formatTimes(migrated.times)}`;
  } else {
    const letterWeekdays = weekdays
      .slice() // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
      .sort()
      .map((weekdayNum) => allWeekdaysDoubleLetter[weekdayNum])
      .join(", ");
    return `${letterWeekdays} • ${formatTimes(migrated.times)}`;
  }
}

function formatMonthlySchedule(schedule) {
  const migrated = migrateSchedule(schedule);
  const { day_of_month } = migrated;
  return `${formatOrdinal(day_of_month)} at ${formatTimes(migrated.times)}`;
}

export function formatTimes(times) {
  if (!times) return "";

  const formattedTimes = times.map((time) => {
    const m = moment().hour(time.hour).minute(time.minute);

    if (prefers12HoursFormat()) {
      return m.format("h:mm a");
    } else {
      return m.format("H:mm");
    }
  });

  return formattedTimes.join(", ");
}

function formatOrdinal(number) {
  // https://en.wikipedia.org/wiki/English_numerals#Ordinal_numbers

  const leastSignificantDigit = number % 10;
  const secondLeastSignificantDigit = Math.floor(number / 10) % 10;

  if (secondLeastSignificantDigit === 1) {
    return `${number}th`;
  }

  let suffix;
  // prettier-ignore
  switch (leastSignificantDigit) {
    case 1: suffix = "st"; break;
    case 2: suffix = "nd"; break;
    case 3: suffix = "rd"; break;
    default: suffix = "th"; break;
  }
  return `${number}${suffix}`;
}

export { formatDailySchedule, formatWeeklySchedule, formatMonthlySchedule, formatOrdinal };
